import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { AxiosError } from 'axios';

import { ApiStatus } from 'Enums';

import { useAccount, useApiError } from 'Hooks';

import { Spinner } from 'Components/utility';
import Wizard from 'Components/wizard';
import WizardMainContainer from 'Components/wizard/WizardMainContainer';

import { REGISTER, REGISTER_CLEAR } from 'Reducers/register/types';

import { logInfo } from 'Utilities/appCommunicator';
import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';
import { persistAuthSession } from 'Utilities/session';
import { store } from 'Utilities/store';

import { signIn } from 'Services/auth';
import { registerPatient } from 'Services/patient';

import { RootState } from 'src/reducers';

import RegisterAccountInfo from './steps/RegisterAccountInfo';
import RegisterConfirmCountry from './steps/RegisterConfirmCountry';
import RegisterLegalNotices from './steps/RegisterLegalNotices';
import RegisterMinorAccountInfo from './steps/RegisterMinorAccountInfo';
import RegisterMinorIntro from './steps/RegisterMinorIntro';
import RegisterMinorPersonalInfo from './steps/RegisterMinorPersonalInfo';
import RegisterPersonalInfo from './steps/RegisterPersonalInfo';

export type RegisterProps = {
  handleSubmitRegister(register: Register): Promise<void>;
  handleClickSignIn(): void;
  validateNameLength(firstName: string, lastName: string): boolean;
  validateIsMinor(dateOfBirth: string, adultAge: number): boolean;
  validateDOBAndDisplayError(dateOfBirth: string): boolean;
  loading: boolean;
};

const mapStateToProps = ({
  nav: { query },
  register,
  env: { app, cid },
  config: { validation, adultAges },
}: RootState) => {
  return {
    query,
    app,
    cid,
    validation,
    adultAges,
    register,
    firstNameLimit: validation?.libreSharingApi.patient.firstName.stringMaxLength,
    lastNameLimit: validation?.libreSharingApi.patient.lastName.stringMaxLength,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const CreateNewUser: React.FC<Props> = ({
  app,
  validation,
  register,
  firstNameLimit,
  lastNameLimit,
}) => {
  const [loading, setLoading] = useState(false);

  const { showApiErrorModal } = useApiError();

  const {
    showEmailExistsMessage,
    showExistingProUserError,
    showPasswordValidationError,
    validateEntriesLengthAndDisplayError,
    validateDOBAndDisplayError,
    validateIsMinor,
  } = useAccount();

  const makeSteps = useMemo(() => {
    if (register.isMinor) {
      return [
        { component: RegisterConfirmCountry, componentName: 'RegisterConfirmCountry' },
        { component: RegisterLegalNotices, componentName: 'RegisterLegalNotices' },
        { component: RegisterPersonalInfo, componentName: 'RegisterPersonalInfo' },
        { component: RegisterMinorIntro, componentName: 'RegisterMinorIntro' },
        { component: RegisterMinorPersonalInfo, componentName: 'RegisterMinorPersonalInfo' },
        { component: RegisterMinorAccountInfo, componentName: 'RegisterMinorAccountInfo' },
      ];
    }

    return [
      { component: RegisterConfirmCountry, componentName: 'RegisterConfirmCountry' },
      { component: RegisterLegalNotices, componentName: 'RegisterLegalNotices' },
      { component: RegisterPersonalInfo, componentName: 'RegisterPersonalInfo' },
      { component: RegisterAccountInfo, componentName: 'RegisterAccountInfo' },
    ];
  }, [register.isMinor]);

  const handleClickSignIn = useCallback(() => {
    mediator.publish('router:navigate', '/login');

    store.dispatch({ type: REGISTER_CLEAR });
  }, []);

  const validateNameLength = useCallback(
    (firstName: string, lastName: string) => {
      const isValid = validateEntriesLengthAndDisplayError([
        {
          value: firstName,
          msgKey:
            'CreateAccountAccountInfo.modals.accountInfoErrors.validationRules.firstNameMaxLength',
          count: firstNameLimit,
        },
        {
          value: lastName,
          msgKey:
            'CreateAccountAccountInfo.modals.accountInfoErrors.validationRules.lastNameMaxLength',
          count: lastNameLimit,
        },
      ]);

      return isValid;
    },
    [firstNameLimit, lastNameLimit, validateEntriesLengthAndDisplayError]
  );

  const handleApiRequestError = useCallback(
    (error: AxiosError<ApiErrorData>) => {
      const data = error.response?.data
        ? error.response.data
        : { details: i18n.t('Global.modals.errorCommunicatingServer.body') };

      logInfo('adc-webview:create-account', { status: error.status, data });

      switch (error.status) {
        case ApiStatus.CONFLICT: {
          const code = error.response?.data.code;

          if (code === ApiStatus.FORBIDDEN_HCP_ACCOUNT) {
            showExistingProUserError();
            return;
          }

          showEmailExistsMessage(handleClickSignIn);
          break;
        }

        case ApiStatus.BAD_REQUEST: {
          const code = error.response?.data.code;
          const errors = error.response?.data.errors;

          if (code === ApiStatus.PASSWORD_VALIDATION) {
            showPasswordValidationError({ validation }, errors);
            return;
          }

          showApiErrorModal();

          break;
        }

        default:
          showApiErrorModal();
      }
    },
    [
      handleClickSignIn,
      showApiErrorModal,
      showEmailExistsMessage,
      showExistingProUserError,
      showPasswordValidationError,
      validation,
    ]
  );

  const handleSubmitRegister = useCallback(
    (register: Register) => {
      return new Promise<void>((resolve, reject) => {
        setLoading(true);

        registerPatient(register, app)
          .then(async () => {
            const { accessToken, accountId, expires } = await signIn(
              register.email,
              register.password,
              app
            );

            persistAuthSession({ accessToken, accountId, expires, app });

            store.dispatch({
              type: REGISTER,
              register: { ...register, patientId: accountId },
            });

            if (register.country === 'US') {
              mediator.publish('router:navigate', '/hipaa');
              resolve();
              return;
            }

            mediator.publish('router:navigate', '/rwe');

            resolve();
          })
          .catch((error) => {
            handleApiRequestError(error);
            reject(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
    [app, handleApiRequestError]
  );

  return (
    <React.Fragment>
      <Suspense fallback={<Spinner />}>
        <WizardMainContainer>
          <Wizard
            steps={makeSteps}
            handleSubmitRegister={handleSubmitRegister}
            handleClickSignIn={handleClickSignIn}
            validateNameLength={validateNameLength}
            validateIsMinor={validateIsMinor}
            validateDOBAndDisplayError={validateDOBAndDisplayError}
            initialValues={register}
            currentStep={register.currentStep}
            loading={loading}
          />
        </WizardMainContainer>
      </Suspense>
    </React.Fragment>
  );
};

export default connector(CreateNewUser);
