import React, { memo } from 'react';

import { HStack, Stack, Text } from '@adc/parallax-component-library';
import {
  IconValidationInvalid,
  IconValidationNotValidated,
  IconValidationValid,
} from '@adc/parallax-icons';

import i18n from 'Utilities/i18n';

interface Props {
  requirements: Requirement[];
}

const PasswordRequirements = memo(({ requirements }: Props) => {
  return (
    <Stack marginBottom="$4">
      {requirements.map((requirement) => {
        return (
          <HStack alignItems="center" marginTop="$2" key={requirement.data.key}>
            {requirement.isInvalid ? (
              <IconValidationInvalid color="$support.danger.default" />
            ) : requirement.match ? (
              <IconValidationValid color="$support.positive.default" />
            ) : (
              <IconValidationNotValidated color="$neutral.40" />
            )}
            <Text
              marginLeft="$2"
              testID={`Login.content.signInForm.formField.password.helpText.${requirement.data.key}`}
              color={requirement.isInvalid ? '$support.danger.surfaceOnColorAlt' : '$neutral.60'}
              fontSize="$bodySmall.default"
              fontWeight="$bodySmall.default"
            >
              {i18n.t<string>(
                `Login.content.signInForm.formField.password.helpText.${requirement.data.key}`,
                {
                  count: requirement.data.value,
                }
              )}
            </Text>
          </HStack>
        );
      })}
    </Stack>
  );
});

PasswordRequirements.displayName = 'PasswordRequirements';

export { PasswordRequirements };
