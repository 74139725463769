import { useCallback } from 'react';

import { redirect } from 'Utilities/browser';
import { store } from 'Utilities/store';

import { getLegacyToken, signIn } from 'Services/auth';

import useAuth from '../../useAuth';

const useCommercialApiAuth = () => {
  const {
    handleLoginError,
    handleLockoutError,
    handleForgotPasswordDone,
    handleResetPasswordDone,
    handleCountryRegionError,
    handleUnexpectedError,
    showHcpError,
    showForgotPasswordBlockedMessage,
    showForgotPasswordConfirmatioMessage,
    showTokenExpirationError,
    showCountryRegionError,
    isLocked,
    lockoutMessage,
    lockoutSubmessage,
  } = useAuth();

  const authenticateUser = useCallback(async (email: string, password: string) => {
    const { app, pId } = store.getState().env;
    const { sharingWebviewsUrl, regions } = store.getState().config;

    if (!sharingWebviewsUrl || !regions) {
      return;
    }

    const { accessToken, accountId, country } = await signIn(email, password, app);

    const legacyToken = await getLegacyToken(app, accountId, accessToken);

    const regionEntry = Object.entries(regions).find(([, { countries }]) =>
      countries.includes(country)
    );

    const region = regionEntry ? regionEntry[0] : 'eu';

    const legacyConnectedAppsUrl = `${sharingWebviewsUrl}?token=${legacyToken}&pId=${pId}&region=${region}`;

    redirect(legacyConnectedAppsUrl);
  }, []);

  return {
    authenticateUser,
    handleLoginError,
    handleLockoutError,
    handleCountryRegionError,
    handleForgotPasswordDone,
    handleResetPasswordDone,
    handleUnexpectedError,
    showHcpError,
    showForgotPasswordBlockedMessage,
    showForgotPasswordConfirmatioMessage,
    showTokenExpirationError,
    showCountryRegionError,
    isLocked,
    lockoutMessage,
    lockoutSubmessage,
  };
};

export default useCommercialApiAuth;
