import log from 'Utilities/log';
import { clearCookie, clearSession, getSessionObject, setSessionObject } from 'Utilities/storage';

import mediator from './mediator';

export const persistAuthSession = <T>(session: T): void => {
  try {
    setSessionObject('authSession', session);
  } catch (err) {
    log.error(err);
  }
};

export const getAuthSession = <T>(): T | null => {
  try {
    const authSession = getSessionObject<T>('authSession');

    return authSession;
  } catch (e) {
    return null;
  }
};

export const updateSessionLanguage = (language: string) => {
  setSessionObject('language', language);
};

export const getSessionLanguage = () => {
  return getSessionObject<string>('language');
};

export const logout = () => {
  clearSession();
  clearCookie('authSession');
  mediator.publish('router:navigate', '/login');
};
