import React from 'react';

import { Text, VStack } from '@adc/parallax-component-library';

import { Main, ResponsiveContainer } from 'Components/utility';

import i18n from 'Utilities/i18n';

const ResetPasswordLinkNotValid = () => {
  return (
    <Main>
      <img
        src={i18n.t<string>('Global.microcopy.common.libreLogoLight')}
        alt=""
        id="Global.microcopy.common.libreLogo"
        style={{
          position: 'absolute',
          zIndex: 1,
          marginTop: '20px',
          width: '100px',
          height: '50px',
          objectFit: 'contain',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
      <ResponsiveContainer>
        <img
          src={i18n.t<string>('LinkNotValid.content.linkNotValidMsg.media.pwResetLock')}
          alt=""
          id="ResetPasswordLinkNotValid.pwResetLock"
          style={{
            marginTop: '40px',
            height: '105px',
            objectFit: 'contain',
          }}
        />
        <VStack
          marginTop="$7"
          gridRowGap="$2"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            nativeID="ResetPasswordLinkNotValid.title"
            fontWeight="$h2.strong"
            fontSize="$h2.strong"
            color="$text.100"
            display="flex"
            flexDirection="column"
            textAlign="center"
          >
            {i18n.t('LinkNotValid.title')}
          </Text>
          <Text
            nativeID="ResetPasswordLinkNotValid.primaryText"
            fontWeight="$bodyBase.default"
            fontSize="$bodyBase.default"
            color="$text.100"
            display="flex"
            flexDirection="column"
            textAlign="center"
          >
            {i18n.t('LinkNotValid.content.linkNotValidMsg.primaryText')}
          </Text>
        </VStack>
        <VStack width="90%" marginHorizontal="auto" marginTop="$12">
          <Text
            nativeID="ResetPasswordLinkNotValid.secondaryText"
            fontWeight="$bodyBase.default"
            fontSize="$bodyBase.default"
            color="$text.100"
            display="flex"
            flexDirection="column"
            textAlign="center"
          >
            {i18n.t('LinkNotValid.content.linkNotValidMsg.secondaryText')}
          </Text>
        </VStack>
      </ResponsiveContainer>
    </Main>
  );
};

export default ResetPasswordLinkNotValid;
