interface Urls {
  libreSharingApiUrl: string;
  lvapiURL: string;
  libreApiCountryUrl: string;
}

/** Reference used to store urls */
export const urls: Urls = {
  libreSharingApiUrl: '',
  lvapiURL: '',
  libreApiCountryUrl: '',
};

/**
 * Sets the urls object to be ready for services
 * - config: object - The environment config
 */
export const setUrls = (config: Partial<Config>, country?: string): void => {
  const { libreSharingApiUrl, lvapiURL, libreApiCountryUrls } = config;

  Object.assign(urls, {
    libreSharingApiUrl,
    lvapiURL,
    libreApiCountryUrl:
      country && libreApiCountryUrls ? libreApiCountryUrls[country] : libreSharingApiUrl,
  });
};

export const getDocumentPathFromLanguage = (
  document: { [key: string]: string } | undefined,
  lang: string
): string => {
  if (!document) {
    return '';
  }

  const path = document[lang.toLowerCase()] || document.default || document['en-us'];

  return path;
};

export const detectTheme = (
  urlParams: URLSearchParams
): 'polarisDark' | 'polarisLight' | 'otcDark' | 'otcLight' => {
  const productParam = urlParams.get('product') as 'libre1' | 'otc' | null;

  const theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'Dark' : 'Light';

  return !productParam || productParam === 'libre1' ? `polaris${theme}` : `otc${theme}`;
};

export const getTheme = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const themeParam = urlParams.get('theme') as
    | 'polarisLight'
    | 'polarisDark'
    | 'otcLight'
    | 'otcDark'
    | null;

  return themeParam ? themeParam : detectTheme(urlParams);
};

export const generateUserAgentHeader = (
  app?: string,
  appVersion?: string,
  osType?: string,
  osVersion?: string
) => {
  return `${app ? app : 'none'};${appVersion ? appVersion : 'none'};${osType ? osType : 'none'};${
    osVersion ? osVersion : 'none'
  }`;
};
