export const SET_ENV = 'ENV';

export interface EnvState {
  redirect_uri: string;
  cid: string;
  app: App;
  country: string;
  pId: string;
}

interface EnvAction {
  type: typeof SET_ENV;
  env: Partial<EnvState>;
}

export type EnvActions = EnvAction;
