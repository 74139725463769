import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { usePartner } from 'Hooks';

import { LogoActionBar, Main, Spinner } from 'Components/utility';

import { RootState } from 'Reducers/index';

import { redirect } from 'Utilities/browser';
import { getAuthSession } from 'Utilities/session';
import { clearSession } from 'Utilities/storage';

import { createConnection } from 'Services/insulet';

const mapStateToProps = ({ env: { redirect_uri } }: RootState) => {
  return {
    redirect_uri,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const InsuletConnection: React.FC<Props> = ({ redirect_uri }) => {
  const { handleUnexpectedError } = usePartner();

  useEffect(() => {
    const initializeConnection = async () => {
      try {
        const authSession = getAuthSession<InsuletAuthSession>();

        if (authSession) {
          const { cid, accountId, accessToken } = authSession;

          const redirectUrl = await createConnection(cid, redirect_uri, accountId, accessToken);

          redirect(redirectUrl);

          clearSession();

          return;
        }

        handleUnexpectedError();
      } catch (err) {
        handleUnexpectedError();
      }
    };

    initializeConnection();
  }, [handleUnexpectedError, redirect_uri]);

  return (
    <Main>
      <LogoActionBar testID="Connection" noActionButton />
      <Spinner testID="Connection" />
    </Main>
  );
};

export default connector(InsuletConnection);
