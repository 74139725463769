import { useMemo } from 'react';

import ForgotPasswordPage from 'Components/auth/ForgotPassword';
import InsuletConnectionPage from 'Components/auth/InsuletConnection';
import LoginPage from 'Components/auth/Login';
import ResetPasswordPage from 'Components/auth/ResetPassword';
import Document from 'Components/consents/Document';
import HipaaConsent from 'Components/consents/HipaaConsent';
import RWEConsent from 'Components/consents/RWEConsent';
import RegisterPage from 'Components/register/CreateNewUser';
import AuthErrorPage from 'Components/utility/AuthErrorPage';
import InitializationErrorPage from 'Components/utility/InitializationErrorPage';

const routes: { route: string; component: React.FC<any>; props?: any }[] = [
  { route: 'login', component: LoginPage },
  { route: 'connection', component: InsuletConnectionPage },
  { route: 'forgot-password', component: ForgotPasswordPage },
  { route: 'reset-password', component: ResetPasswordPage },
  { route: 'create-account', component: RegisterPage },
  { route: 'hipaa', component: HipaaConsent },
  { route: 'rwe', component: RWEConsent },
  { route: 'terms-of-use', component: Document, props: { type: 'toupat' } },
  { route: 'privacy-notice', component: Document, props: { type: 'pnpat' } },
  { route: 'auth-error', component: AuthErrorPage },
];

const useRouter = (
  location: string,
  initErrorStatus?: number,
  initErrorCode?: string,
  redirect_uri?: string
) => {
  const { Component, props } = useMemo(() => {
    if (initErrorStatus) {
      return {
        Component: InitializationErrorPage,
        props: { errorStatus: initErrorStatus, errorCode: initErrorCode, redirect_uri },
      };
    }

    const lowerCaseLocation = location.toLowerCase();

    const route = routes.find((r) => lowerCaseLocation.startsWith(`/${r.route.toLowerCase()}`));

    if (route) {
      return { Component: route.component, props: route.props };
    }

    return { Component: LoginPage };
  }, [initErrorCode, initErrorStatus, location, redirect_uri]);

  return { Component, props };
};

export default useRouter;
