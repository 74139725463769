import { NAVIGATION, NavigationAction } from '../nav/types';
import { EnvActions, EnvState, SET_ENV } from './types';

const initialState: EnvState = {
  cid: '',
  redirect_uri: '',
  country: 'US',
  app: 'insulet',
  pId: '',
};

export const envReducer = (
  state = initialState,
  action: NavigationAction | EnvActions
): EnvState => {
  switch (action.type) {
    case NAVIGATION:
      return {
        ...state,
        app: action.context.query?.app || state.app,
        country: action.context.query?.country?.toUpperCase() || state.country,
        redirect_uri: action.context.query?.redirect_uri || state.redirect_uri,
        cid: action.context.query?.cid || state.cid,
        pId: action.context.query?.pId || state.pId,
      };
    case SET_ENV:
      return {
        ...state,
        ...action.env,
      };
    default:
      return state;
  }
};
