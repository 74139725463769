const partnersConfig = {
  insulet: {
    displayActionBarHeader: true,
  },
  commercialapi: {
    displayActionBarHeader: false,
  },
};

export default partnersConfig;
