import React, { useCallback } from 'react';

import { Text } from '@adc-polaris-component-library/component-library';
import { differenceInYears, parseISO } from 'date-fns';
import { format } from 'date-fns';
import { Button, Divider, Stack } from 'native-base';

import { RichText } from 'Components/utility';

import { ConfigState } from 'Reducers/config/types';
import { CLOSE_MODAL, SHOW_MODAL } from 'Reducers/modal/types';

import i18n from 'Utilities/i18n';
import { store } from 'Utilities/store';

export interface EntryItem {
  value: string;
  msgKey: string;
  count?: number;
}

type ValidationType = Pick<ConfigState, 'validation'>;

const useAccount = () => {
  const showEmailExistsMessage = useCallback((onClickSignIn: () => void) => {
    store.dispatch({
      type: SHOW_MODAL,
      modalProps: {
        body: (
          <Stack space={4}>
            <Text
              color="text.100"
              fontWeight={'bodyBaseDefault'}
              fontFamily={'bodyBaseDefault'}
              fontSize="base"
              nativeID="RegisterAccountInfo.accountExists"
              mb={1}
            >
              {i18n.t<string>('CreateAccountAccountInfo.modals.accountInfoErrors.emailExists', {
                appName: i18n.t<string>('Global.appNames.libreView'),
              })}
            </Text>
            <Text
              color="text.100"
              fontWeight={'bodyBaseDefault'}
              fontFamily={'bodyBaseDefault'}
              fontSize="base"
              nativeID="RegisterAccountInfo.trySignIn"
              mb={1}
            >
              {i18n.t<string>('CreateAccountAccountInfo.modals.accountInfoErrors.trySignIn')}
            </Text>
          </Stack>
        ),
        footer: (
          <Stack space={1} width="100%">
            <Button
              nativeID="modal-button-signIn"
              variant="link"
              _text={{
                color: 'interactive.text.active',
                fontWeight: 'bodyBaseMedium',
                fontFamily: 'bodyBaseMedium',
                textDecoration: 'none',
                fontSize: 'base',
              }}
              onPress={() => {
                store.dispatch({
                  type: CLOSE_MODAL,
                });
                onClickSignIn();
              }}
            >
              {i18n.t<string>('CreateAccountAccountInfo.modals.accountInfoErrors.signIn')}
            </Button>
            <Divider
              _light={{
                bg: 'neutral.40',
              }}
              _dark={{
                bg: 'neutral.40',
              }}
            />
            <Button
              nativeID="modal-button-cancel"
              variant="link"
              _text={{
                color: 'interactive.text.active',
                fontWeight: 'bodyBaseDefault',
                fontFamily: 'bodyBaseDefault',
                textDecoration: 'none',
                fontSize: 'base',
              }}
              onPress={() => {
                store.dispatch({
                  type: CLOSE_MODAL,
                });
              }}
            >
              {i18n.t<string>('CreateAccountAccountInfo.modals.accountInfoErrors.cancel')}
            </Button>
          </Stack>
        ),
      },
    });
  }, []);

  const showExistingProUserError = useCallback(() => {
    store.dispatch({
      type: SHOW_MODAL,
      modalProps: {
        body: (
          <Stack space={4} nativeID="Global.modals.blockHCPUser.body">
            <RichText
              text={i18n.t<string>(
                'CreateAccountAccountInfo.modals.accountInfoErrors.emailExistsProfessional',
                {
                  appName: i18n.t<string>('Global.appNames.libreView'),
                }
              )}
            />
          </Stack>
        ),
      },
    });
  }, []);

  const validateEntriesLengthAndDisplayError = (items: EntryItem[]) => {
    const messages: { msgKey: string; count: number }[] = [];

    for (const item of items) {
      if (item.count && item.value.length > item.count) {
        messages.push({ msgKey: item.msgKey, count: item.count });
      }
    }

    if (messages.length) {
      store.dispatch({
        type: SHOW_MODAL,
        modalProps: {
          messages,
        },
      });

      return false;
    }

    return true;
  };

  const showPasswordValidationError = useCallback(
    ({ validation }: ValidationType, errors?: { error: string; [key: string]: string }[]) => {
      store.dispatch({
        type: SHOW_MODAL,
        modalProps: {
          body: (
            <Stack space={4}>
              <Text
                color="text.100"
                fontWeight="bodyBaseDefault"
                fontFamily="bodyBaseDefault"
                fontSize="base"
                nativeID="CreateAccountAccountInfo.modals.accountInfoErrors.differentPasswordTitle"
                mb={1}
              >
                {i18n.t<string>(
                  'CreateAccountAccountInfo.modals.accountInfoErrors.differentPasswordTitle'
                )}
              </Text>

              {errors?.map((e) => {
                const limit: number | undefined =
                  e.error === 'max'
                    ? validation?.identity.find((item) => item.data.key === e.error)?.data.value
                    : validation?.identity.find((item) => item.type === e.error)?.data.limit;

                return (
                  <Text
                    key={e.error}
                    color="text.100"
                    fontWeight="bodyBaseDefault"
                    fontFamily="bodyBaseDefault"
                    fontSize="base"
                    nativeID={`CreateAccountAccountInfo.modals.accountInfoErrors.securityRules.${e.error}`}
                    mb={1}
                  >
                    {i18n.t<string>(
                      `CreateAccountAccountInfo.modals.accountInfoErrors.securityRules.${e.error}`,
                      {
                        count: limit ? limit : 0,
                      }
                    )}
                  </Text>
                );
              })}
            </Stack>
          ),
        },
      });
    },
    []
  );

  const validateIsMinor = useCallback((dateOfBirth: string, adultAge: number) => {
    const dob = parseISO(dateOfBirth);
    const age = differenceInYears(new Date(), dob);

    return age < adultAge;
  }, []);

  const validateDOBAndDisplayError = useCallback((dateOfBirth: string) => {
    const today = format(new Date(), 'yyyy-MM-dd');

    if (dateOfBirth > today) {
      store.dispatch({
        type: SHOW_MODAL,
        modalProps: {
          message: {
            msgKey: 'CreateAccountPersonalInfo.modals.personalInfoErrors.dobError',
          },
        },
      });
      return false;
    }

    return true;
  }, []);

  return {
    showEmailExistsMessage,
    showPasswordValidationError,
    showExistingProUserError,
    validateEntriesLengthAndDisplayError,
    validateIsMinor,
    validateDOBAndDisplayError,
  };
};

export default useAccount;
