import { useCallback, useEffect } from 'react';

import { InsuletStatusCode } from 'Enums';

import { redirect } from 'Utilities/browser';
import mediator from 'Utilities/mediator';
import { persistAuthSession } from 'Utilities/session';
import { store } from 'Utilities/store';

import { signIn } from 'Services/auth';
import { verifyCid } from 'Services/insulet';

import useAuth from '../../useAuth';
import useInsuletError from './useInsuletError';

const useInsuletAuth = () => {
  const { redirectOnError } = useInsuletError();

  const onDisplayLockoutMessage = useCallback(() => {
    setTimeout(() => {
      const redirectUrl = store.getState().env.redirect_uri;

      redirectOnError(redirectUrl, InsuletStatusCode.LOCKOUT_ERROR);
    }, 5000);
  }, [redirectOnError]);

  const {
    handleLoginError,
    handleLockoutError,
    showHcpError,
    showForgotPasswordBlockedMessage,
    showForgotPasswordConfirmatioMessage,
    showTokenExpirationError,
    showCountryRegionError,
    isLocked,
    lockoutMessage,
    lockoutSubmessage,
  } = useAuth(onDisplayLockoutMessage);

  useEffect(() => {
    const sessionExpiredInterval = setTimeout(() => {
      const redirectUrl = store.getState().env.redirect_uri;

      redirectOnError(redirectUrl, InsuletStatusCode.INTERNAL_ERROR);
    }, 600000);

    return () => {
      clearTimeout(sessionExpiredInterval);
    };
  }, [redirectOnError]);

  const authenticateUser = useCallback(async (email: string, password: string) => {
    const { cid, country, redirect_uri, app } = store.getState().env;

    const { accessToken, accountId, expires } = await signIn(email, password, app);

    const redirectUrl = await verifyCid(cid, country, redirect_uri, accountId, accessToken);

    redirect(redirectUrl);

    persistAuthSession<InsuletAuthSession>({
      accessToken,
      accountId,
      expires,
      cid,
      app,
      country,
    });
  }, []);

  const handleCountryRegionError = useCallback(() => {
    const redirectUrl = store.getState().env.redirect_uri;

    redirectOnError(redirectUrl, InsuletStatusCode.WRONG_REGION);
  }, [redirectOnError]);

  const handleForgotPasswordDone = useCallback(() => {
    const redirectUrl = store.getState().env.redirect_uri;

    redirectOnError(redirectUrl, InsuletStatusCode.FORGOT_PASSWORD);
  }, [redirectOnError]);

  const handleResetPasswordDone = useCallback(() => {
    const partners = store.getState().config.partners;
    const pId = store.getState().env.pId;

    const website = partners ? partners[pId]?.website : '';

    if (website) {
      redirect(website);
    }
  }, []);

  const handleUnexpectedError = useCallback(() => {
    mediator.publish('router:navigate', '/auth-error');

    setTimeout(() => {
      const redirectUrl = store.getState().env.redirect_uri;

      redirectOnError(redirectUrl, InsuletStatusCode.INTERNAL_ERROR);
    }, 5000);
  }, [redirectOnError]);

  return {
    authenticateUser,
    handleLoginError,
    handleLockoutError,
    handleCountryRegionError,
    handleForgotPasswordDone,
    handleResetPasswordDone,
    handleUnexpectedError,
    showHcpError,
    showForgotPasswordBlockedMessage,
    showForgotPasswordConfirmatioMessage,
    showTokenExpirationError,
    showCountryRegionError,
    isLocked,
    lockoutMessage,
    lockoutSubmessage,
  };
};

export default useInsuletAuth;
