import jwtDecode from 'jwt-decode';

import { urls } from 'Utilities/dataHelpers';
import * as log from 'Utilities/log';
import mediator from 'Utilities/mediator';

export const signIn = (email: string, password: string, app: App) => {
  return new Promise<{
    accessToken: string;
    accountId: string;
    expires: number;
    country: string;
  }>((resolve, reject) => {
    mediator
      .request<ApiResponse<AuthResponse>>('api:invoke', {
        method: 'POST',
        url: urls.libreApiCountryUrl + 'v1/login',
        headers: {
          'X-User-Agent': app,
        },
        data: {
          email,
          password,
          consents: [
            {
              id: 'touLibre',
              action: 'accept',
            },
            {
              id: 'pp',
              action: 'accept',
            },
          ],
        },
      })
      .then((result) => {
        if (result.data) {
          const decodedToken = jwtDecode<{
            id: string;
            did: string;
            exp: number;
            iat: number;
            country: string;
          }>(result.data.access_token);

          resolve({
            accessToken: result.data.access_token,
            accountId: decodedToken.id,
            expires: decodedToken.exp,
            country: decodedToken.country,
          });
          return;
        }

        reject(result);
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const forgotPass = (email: string, app: string, pId: string) => {
  return new Promise<void>((resolve, reject) => {
    mediator
      .request<ApiResponse<void>>('api:invoke', {
        method: 'POST',
        url: urls.libreApiCountryUrl + 'v1/password/forgot',
        headers: {
          'X-User-Agent': app,
        },
        data: {
          email,
          partnerID: pId,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const resetPass = (password: string, tokenID?: string, app?: string) => {
  return new Promise<void>((resolve, reject) => {
    mediator
      .request<ApiResponse<void>>('api:invoke', {
        method: 'POST',
        url: urls.libreApiCountryUrl + 'v1/password/reset',
        headers: {
          'X-User-Agent': app,
        },
        data: {
          password,
          tokenID,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const getLegacyToken = (app: string, accountId: string, accessToken: string) => {
  return new Promise<string>((resolve, reject) => {
    mediator
      .request<ApiResponse>('api:invoke', {
        method: 'GET',
        url: urls.libreApiCountryUrl + `v1/patients/${accountId}/legacy-sharing-webviews-token`,
        headers: {
          'X-User-Agent': app,
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        if (result.data) {
          resolve(result.data.tokenId);
          return;
        }

        reject();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};
